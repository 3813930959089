.sidebar-contanior{
    background-color: #006D34;
    padding-top: 15px;
    display: block;
    height: 100vh;
    position: relative;
   
}
.Menu{
    display: none;
}
.menu-box{
    /* padding-left: 30px; */
    margin-top: 20px;
}
.bar-title{
    font-size: 20px;
    font-weight: 400;
    color: #61DE86;
    margin-left: 12px;
    position: absolute;
    left: 52px;
}
.link-box{
 height: 48px;
 width: 200px;
 padding-left: 30px;
 padding-top: 8px;   
 position: relative;
}
.link-box:hover{
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 5px;
}
.logout{
    position: absolute;
    bottom: 160px;
    height: 48px;
    width: 200px;
    padding-left: 10%;
    padding-top: 8px;  
}
.logout:hover{
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 5px;
}
.user-img{
    position: absolute;
    bottom: 15px;
    height: 130px;
    width: 70%;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 15%;

}
.user-name{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5%;
}
.side-link{
    text-decoration: none;
}
.bar-img{
    position: absolute;
    top: 12px;
}

@media (max-width: 767px) {
.sidebar-contanior{
display: none;
}
.Menu{
    display: block;
}
}

.active .link-box{
    background-color: #FFFFFF;
    border-radius: 5px; ;
}