.content{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 5px;
    
}
.heading{
    font-size: 64px;
    font-weight: 600;
    padding-left: 4% !important;
}
.search-item{
    margin-left: 10px;
}
.input-search{
    display: flex;
    margin-top: 2px;
   
 }
 .search-input{
    width: 800px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 8px 0px 0px 8px ;
    padding: 10px;
    border: 1px solid #e3e3e3;
 }
 .search-button{
    height: 48px;
    width: 100px;
    background-color: #006D34;
    color: #ffff;
    border: 1px solid #006D34;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 1px;
 }
 .course-links{
    text-decoration: none;
  color: #42C26D;
  font-size: 16px;
  font-weight: 400;
  }
  .link{
    cursor: pointer;
  }
  /* My Course */
  .my-text{
    font-size: 24px;
    font-weight: 400;
    width: 70%;
    margin-left: 45px;
  }
  .my-button{
    height: 60px;
    width: 216px;
    background-color: #006D34;
    color: #ffff;
    border: 1px solid #006D34;
    border-radius: 8px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    margin-left: 45px;
    margin-top: 30px;
  }
 @media (max-width: 767px) {
    
    .search-input{
        width: 350px;
    }
    .search-item{
        margin-left: 10px;
    }
    .heading{
        font-size: 32px;
        font-weight: 600;
        padding-left: 7% !important;
    }
    .my-text{
        margin-left: 15px;
        width: 90%;
    }
    .my-button{
        margin-left: 15px;
    }
  }
