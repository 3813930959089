.heading1{
    font-size: 64px;
    font-weight: 600;
    padding-left: 4% !important;
}
.upload-box{
    border: 2px solid #2e7d32;
    border-radius: 5px ;
    text-align: center;
}
.add-upload{
font-size: 14px;
color: #2e7d32;
font-weight: 600;
}
.add-drag{
font-size: 14px;
font-weight: 400;
margin-left: 5px;
}
.add-file{
font-size: 12px;
margin-top: 2px;
margin-bottom: 5px;
}
/*  */
.prview-box{
   border-radius: 5px;
   background-color: #F5FFF2; 
   padding: 20px;
}
.p-label{
color: #006D34;
font-size: 16px;
font-weight: 600;
margin-top: 20px;
}
.p-text{
font-size: 20px;
font-weight: 400;
margin-top: 10px;
}