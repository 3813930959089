.cb1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
    height: 459px;
    width: 100%;
    background-color: #61ED66;
    justify-content: space-around;
}
.form-heading{
    font-size: 32px;
    font-weight: 600;
}
.contactheading{
    font-size:72px; 
    font-weight: 600; 
    color: #ffff;

}


@media screen and (max-width: 600px) {
  .contactheading{
    font-size: 50px;
  }
    
 }
 