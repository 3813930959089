.about-box1{
 display: flex;
 flex-wrap: wrap;
 justify-content: space-around;
 align-items: center;
 background-color: #61ED66;
}
.about-text{
    font-size: 72px;
    font-weight: 600;
    color: #ffff;
}

 .about-box2{
    background-color: rgba(97, 237, 102, 0.1);
    padding-top: 2%;
    padding-bottom: 5%;
}
/*
.about-text3{
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
    padding-top: 10px;
}
.about-text2{
    font-size: 32px;
    font-weight: 600;
} */
.about-text1{
    font-size: 24px;
    /* text-transform: capitalize; */
    font-weight: 400;
    padding-top: 10px;
}
.about-heading{
    margin-top: 30px;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
}
.about-text2{
    font-size: 24px;
    font-weight: 400;
    padding-top: 10px;
}
.about-text3{
    font-size: 20px;
    font-weight: 400;
    padding-top: 10px;
}
.about-box3{
   height: 916px;
    display: flex;
    flex-wrap: wrap;
    background-image: url('../assets/AboutUs/Rectangle.png');
    justify-content: space-around;
    align-items: center;
}
.mission{

    width: 30%;
    background-color: #FF4100;
    padding: 2%;
    border-radius: 8px;
    color: #ffff;
}
.card-body{
    width: 282px;
    height: 251px;
    border-top: 1px solid #61ED66;
    border-radius: 0px 0px 8px 8px;
    box-shadow: inset;
}
.avt{
    text-align: -webkit-center;
    margin-top: -38px;
}
.name{
font-size: 24px;
font-weight: 600;
}
.post{
    font-size: 24px;
    font-weight: 400;
}
.card-text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

@media screen and (max-width: 600px) {
.about-text{
    font-size: 40px;
}
.about-text3{
    padding: 5%;
}
.mission{
    width: 90%;
    margin-top: 10px;
}
.about-box3{
    margin-bottom: 100px;
}

}