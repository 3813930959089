.sp-box{
    padding-left: 5px
}
.img-box{
    position: relative;
    width: 100%;
    height: 280px;
}
.sp-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
.level-corses{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
}
.sp-title{
    font-size: 1.5em;
    font-weight: 600;
    padding: 16px;
}
.sp-text{
    font-size:16px;
    font-weight: 400;
    padding: 16px;
}
.sp-button{
    width: 100%;
    height: 48px;
    background-color: #006D34;
    color: #ffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid;
}
@media (max-width: 767px) {
    .sp-box{
        padding: 10px;
    }
}