.hb1{
    display: flex;
    height: 242px;
    width: 100%;
    background-color: #61ED66;
    background-image: url("../assets//HomePage/welcome.png");
    align-items: center;
}
.welcom-text{
        font-size: 84px;
        font-weight: 600;
        color: #ffff;
        padding-left: 5%;
    
}
@media screen and (max-width: 600px) {
    .welcom-text{
        font-size: 50px;
      
}
  }