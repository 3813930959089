
.box-c{
    background-color: #006D34;
}
.haed-text{
 font-size: 48px;
 font-weight: 600;
 color: #ffffff;
 text-align: center;
 padding-top: 30px;
}
.cours-box{
    display: flex; 
    margin-top: 15px; 
    margin-bottom: 20px;
}
.cours-card{
    width: 280px; 
    margin-left: 7%; 
    border: 1px solid #F2F3F4; 
    position: relative; 
    height:500px; 
    border-radius:8px; 
    box-shadow: 2px 2px #eef2f5;
    margin-top: 5px;
    background-color: white;
}
.cf-img{
    padding: 18px;
}
.label{
    font-size: 20px;
    font-weight: 600;
    color: #006D34;
    padding-left: 18px;
}
.text{
    font-size: 20px;
    font-weight: 400;
    color: #006D34;
    padding: 18px;
}
.btn-enroll{
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 10px 18px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}
.btn-enroll2{
    background-color: #E5E7E9; 
    border: 1px solid #E5E7E9;
    color: #B3B6B7;
    padding: 10px 18px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 4px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

@media screen and (max-width: 600px) {
    .cours-box{
        flex-direction: column;
    }
 }
 