.SignUp-container{
height: 100vh;
width: 100%;
background-color: #006D34;
position: relative;
}

.signup-img{
    position: absolute;
    height: 92vh;
    width: 70%;
}
.form-box{
    position: absolute;
    height: 86vh;
    width: 40%;
    z-index: 1;
    background-color: #ffff;
    top: 45px;
    right: 5%;
    border-radius: 20px;
    overflow: auto
}
.img-mobile{
    margin-left: 5%;
    display: none;
}
@media (max-width: 767px) {
    .signup-img{
        display: none;
    }
    .form-box{
        position: absolute;
        height: 86vh;
        width: 90%;
        z-index: 1;
        background-color: #ffff;
        top: 65px;
        right: 5%;
        border-radius: 20px;
        overflow: auto
    }
    .img-mobile{
        display: block;
    }
}