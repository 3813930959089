.course-box{
    position: relative;
    width: 100%;
    height: 400px;
}
.c-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
.course-name1{
    font-size: 24;
    font-weight: 600;
    color: #C4C7C7;
    margin-left: 30px;
}
.course-name2{
    font-size: 22;
    font-weight: 600;
    color: #2E3132;
    margin-left: 5px;
}
.c-heading{
    font-size: 40px;
    font-weight: 600;
    color: #191C1D;
    margin-top: 16px;
}
.c-text1{
font-size: 20px;
font-weight: 400;
color: #5C5F5F;
margin-top: 10px;
}
.c-text2{
    font-size: 24px;
    font-weight: 600;
    color: #191C1D;
    margin-top: 20px;
}
.c-button{
height: 48px;
width: 100%;
background-color: #006D34;
border-radius: 5px;
text-align: center;
font-size: 16px;
font-weight: 600;
color: #ffff;
margin-top: 20px;
margin-bottom: 10px;
}
.c-text3{
font-size: 20px;
font-weight: 600;
padding-bottom: 10px;
padding-top: 15px;
}
.c-text4{
    font-size: 14px;
    font-weight: 400;
}
.c-text5{
    font-size: 16px;
    font-weight: 600;
}
.c-side-box{
    background-color: #F2F4F4;
    border-radius: 8px;
    margin-top: 20px !important;
    padding-bottom: 10px;
    margin-left: 10px !important;
}
@media (max-width: 767px) {
.c-img{
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
}