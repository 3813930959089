
  .footer{
    display: flex;
    align-content: center;
    padding: 25px 80px;
    align-items: center;
    background-color: #006D34;
    justify-content: space-between;
    border-bottom: 1px solid #008943;
  }
  .f-logo{
    display: flex;
    align-self: start;
  }
  .f-links{
    display: flex;
    color: #ffff;
    gap: 30px;
    align-items: center;
    font-size: 16px;
  }
  .copyright{
    display: flex;
    justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #006D34;
  color: #42C26D;
  }

  @media screen and (max-width: 600px) {
    .f-links {
      display: none;
    }
  }