.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }
  
  .flex-item {
    margin: 10px;
  }
  .s1{
    font-size: 14px;
    border: 1px solid #61DE86;
    border-radius: 12px;
    padding: 6px 24px 6px 24px;
    background-color: #F5FFF2;
    color: #006D34;
  }
  .s2{
    font-size: 64px;
    font-weight: 700;
  }
  .s2-2{
    color: #006D34;
  }
  .s2-3{
    font-size: 48px;
    font-weight: 600;
  }
  .s3{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  .s3-2{
   width: 50%;
  }
  .s4{
    height: 48px;
    width: 152px;
    font-size: 16px;
    border: 1px solid #006D34;
    border-radius: 5px;
    background-color: #006D34;
    color: #ffff;
    text-align: center;
    cursor: pointer;
  }
  .box{
    height: 110vh;
    width: 100%;
    position: relative;
  }
  .image-book {
    position: absolute;
    left: 2%;
    top: 0px;
    width: 10%; 
    height: 17%; 
    background-image: url('../assets/HomePage/book.png'); 
    background-size: cover;
    
  }
  .image-frame{
    position: absolute;
    top: 2%;
    height: 94%;
    width: 94%;
    margin-left: 3%
  }
  .image-f1{
    display: flex;
  justify-content: center;
  align-items: center;
  }
  .f1{
    display: block;
  }
  .f2{
    display: none;
  }
  @media (max-width: 767px) {
    .flex-container {
      justify-content: flex-start; /* Align to the left on mobile */
      align-items: start;
    }
    .s2{
        font-size: 50px;
    }
    .s2-4{
        width: 40px;
    }
    .s3{
        font-size: 18px;
        font-weight: 400;
        text-align: start;
      }
      .box{
        height: 40vh;
        
      }
      .image-book{
        height: 26%;
        width: 22%;
        left: -2%;
      }
      .image-frame{
        top: 5%;
      }
      .f1{
        display: none;
      }
      .f2{
        display: block;
        width: 94%;
      }
      .s3-2{
        width: 90%;
       }
       .s2-3{
        font-size: 42px;
      
      }
  }
  