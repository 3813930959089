.header{
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 25px 60px;
  align-items: center;
}
.links{
  display: flex;
  gap: 30px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.link{
  cursor: pointer;
}
.buttons{
  display: flex;
  gap: 20px;
}
.login-button{
font-size: 16px;
height: 48px;
width: 87px;
border: 1px solid ;
border-radius: 8px;
background-color: #ffff;
cursor: pointer;
}

.signup-button{
  font-size: 16px;
  height: 48px;
  width: 103px;
  border: 1px solid;
  border-radius: 8px;
  background-color: #ffff;
  cursor: pointer;
}

.login-button:hover{
  background-color: #006D34;
  border: 1px solid #006D34;
  color: #ffff;
}
.signup-button:hover{
  background-color: #006D34;
  border: 1px solid #006D34;
  color: #ffff;
}
.nav-links{
  text-decoration: none;
  color: #747878;
  font-size: 16px;
  font-weight: 600;
}
/* Mobile View */
.login2-button{
  font-size: 16px;
  color: #006D34;
  height: 48px;
  width: 159px;
  border: 1px solid #006D34;
  border-radius: 8px;
  background-color: transparent;
  
  }  
  .signup2-button{
    font-size: 16px;
    color: #ffff;
    height: 48px;
    width: 159px;
    border: 1px solid 006D34;
    border-radius: 8px;
    background-color: #006D34;
  }
@media screen and (max-width: 600px) {
 
}
