.button-box{
    display: flex;
    justify-content: center;
    padding-top: 2%;
}
.call-start {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2%;
  }
  
  .call-start:hover {
    background-color: #45a049;
  }
  .call-end {
    background-color: #ce221f; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2%;
  }
  
  .call-end:hover {
    background-color: #ad1310;
  }
  .call-heading{
    font-size: 32px;
    font-weight: 600;
   text-align: center;
   margin-top: 2%;
  }

  .pay-pyment {
    background-color: #ce221f; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 4px;
    margin-right: 2%;
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

@media screen and (max-width: 600px) {
  .call-img{
    width: 90%;
  }
  .call-heading{
    font-size: 24px;
    
  }
}