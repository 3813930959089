
 .box5{
display: flex;
flex-direction: column;
align-items: center;
gap: 50px;
padding: 60px;
 }

 .text2-box{
 width: 75%;
 }
.wave-img{
   width: 290px;
   margin-left: 20px;
}
 @media screen and (max-width: 600px) {
  
   
 }
 
